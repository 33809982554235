import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { App } from './App/App';
import { FormContextProvider } from './contexts/FormContext';
import { TranslationContextProvider } from './contexts/TranslationContext';
import { WixContextProvider } from './contexts/WixContext';

function AppWrapper() {
    return (
        <MantineProvider withGlobalStyles withNormalizeCSS>
            <ModalsProvider>
                <WixContextProvider>
                    <TranslationContextProvider>
                        <FormContextProvider>
                            <App />
                        </FormContextProvider>
                    </TranslationContextProvider>
                </WixContextProvider>
            </ModalsProvider>
        </MantineProvider>
    );
}

export default AppWrapper;

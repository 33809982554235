import { useContext } from 'react';
import FormContext from '../../../contexts/FormContext';
import styles from './AppInfoPreview.module.css';

interface IAppInfoPreview {
    stage: number;
}

const urlPrefixes = ["h", "ht", "htt", "http", "http:", "http:/", "http://", "https", "https:", "https:/", "https://"];

export const AppInfoPreview = ({ stage }: IAppInfoPreview) => {
    const { state: {
        companyName,
        companyDomain,
        logoImage,
        favIcon,
        primaryColor,
        secondaryColor,
        infoName,
        infoDescription,
        infoAddress1,
        infoAddress2,
        infoEmail,
        infoPhoneNumber,
    } } = useContext(FormContext);

    let cDomain = companyDomain.value === "" ? "https://designer.mycompany.com" : companyDomain.value;
    let found = false;
    for (let i = 0; i < urlPrefixes.length; i++) {
        if (cDomain.startsWith(urlPrefixes[i])) {
            found = true;
            break;
        }
    }
    if (!found) {
        cDomain = "https://" + cDomain;
    }

    const hasFavIcon = (stage !== 0) && favIcon?.value?.dataUrl;
    const hasLogoImage = (stage !== 0) && logoImage?.value?.dataUrl;

    return (
        <div className={stage > 2 ? styles.colContainer : styles.container}>
            <div className={styles.preview}>
                <div className={styles.header}>
                    <div className={styles.tab}>
                        {!hasFavIcon && <div className={styles.icon} />}
                        {hasFavIcon &&
                            <img alt="" src={favIcon.value.dataUrl} style={{ width: 16, height: 16, marginRight: 4, maxWidth: '98%', maxHeight: '100%' }} />
                        }
                        {companyName.value === "" ? "My Company Designer" : companyName.value}
                    </div>
                </div>
                <div className={styles.urlBar}>
                    <div className={styles.urlBox}>
                        {cDomain}
                    </div>
                </div>
                {stage !== 0 &&
                    <div className={styles.editor}>
                        <div className={styles.editorHeader}>
                            {!hasLogoImage && <div className={`${styles.editorIcon} ${styles.editorIconEmpty}`} />}
                            {hasLogoImage &&
                                <img alt="" className={styles.editorIcon} src={logoImage.value.dataUrl} style={{ width: 222, height: 44, maxWidth: '98%', maxHeight: '100%' }} />
                            }
                            <div className={styles.flex1} />
                            <div className={styles.mainButton} style={{ backgroundColor: `${primaryColor.value}` }} />
                            <div className={styles.secondaryButton} style={{ backgroundColor: secondaryColor.value }} />
                            <div className={styles.secondaryButton} style={{ backgroundColor: secondaryColor.value }} />
                        </div>
                        <div className={styles.editorMenu}>
                            <div className={styles.mainButton} style={{ width: 100, backgroundColor: primaryColor.value }} />
                        </div>
                        <div className={styles.editorBody} style={{ height: stage === 3 ? 300 : (stage === 2 ? 200 : 100) }}>
                            <div className={styles.rightBar} />
                            <div className={styles.toolbox} style={{ width: stage === 3 ? 260 : 140 }}>
                                {stage === 3 &&
                                    <>
                                        <div className={styles.toolboxName}>{infoName.value === "" ? "Company Name" : infoName.value}</div>
                                        <div className={styles.toolboxParagraph}>{infoDescription.value === "" ? "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nisl nec ultricies lacinia, nisl nisl aliquam." : infoDescription.value}</div>
                                        <div className={styles.toolboxParagraph}>
                                            {infoAddress1.value === "" ? "1234 Main Street" : infoAddress1.value}
                                            {infoAddress1.value === "" ? ", Suite 1234" : (infoAddress2.value === "" ? "" : ", " + infoAddress2.value)}
                                        </div>
                                        <div className={styles.toolboxParagraph}>{infoEmail.value === "" ? "contact@mycompany.com" : infoEmail.value}</div>
                                        <div className={styles.toolboxParagraph}>{infoPhoneNumber.value === "" ? "(123) 456-7890" : infoPhoneNumber.value}</div>
                                        <div className={styles.toolboxPoweredBy}>Powered by <u>Spacedesigner</u></div>
                                    </>
                                }
                            </div>
                            {stage === 2 && <div className={styles.flex1} />}
                            {stage === 2 &&
                                <div className={styles.popup}>
                                    <div className={styles.popupTitle}>
                                        Lorem Ipsum Dolor
                                    </div>
                                    <div className={styles.popupBody}>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nisl nec ultricies lacinia, nisl nisl aliquam nisl, nec aliquam nisl nisl sit amet nisl.
                                    </div>
                                    <div className={styles.popupButtons}>
                                        <div className={styles.secondaryButton} style={{ backgroundColor: secondaryColor.value }} />
                                        <div className={styles.flex1} />
                                        <div className={styles.mainButton} style={{ backgroundColor: `${primaryColor.value}` }} />
                                    </div>
                                </div>
                            }
                            {stage === 2 && <div className={styles.flex2} />}
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

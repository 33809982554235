import { Text, Textarea, TextInput } from '@mantine/core';
import { useContext } from 'react';
import FormContext from '../../../contexts/FormContext';
import { FormFieldId, FormReducerAction } from '../../../contexts/FormReducer';
import TranslationContext from '../../../contexts/TranslationContext';
import { AppInfoPreview } from '../../Previews/AppInfoPreview/AppInfoPreview';
import { SizedBox } from '../../SizedBox/SizedBox';
import { StepContainer } from '../../StepContainer/StepContainer';
import styles from './CompanyInfo.module.css';

export const CompanyInfo = () => {
    const { t } = useContext(TranslationContext);
    const { state: { infoName, infoDescription, infoEmail, infoAddress1, infoAddress2, infoPhoneNumber }, dispatch } = useContext(FormContext);

    return (
        <StepContainer title={t('company information title')}>
            <Text>{t('company information description')}</Text>
            <div className={styles.row}>
                <div className={styles.col}>
                    <TextInput
                        label={t('company name')}
                        placeholder={t('your company name')}
                        value={infoName.value}
                        onChange={(e) => dispatch({ type: FormReducerAction.Update, field: FormFieldId.infoName, payload: e.target.value })}
                        onBlur={() => dispatch({ type: FormReducerAction.Blur, field: FormFieldId.infoName })}
                        error={infoName.error}
                    />
                    <SizedBox height={8} />
                    <Textarea
                        classNames={{ input: styles.textarea }}
                        label={t('company application description')}
                        placeholder={t('company application placeholder')}
                        value={infoDescription.value}
                        onChange={(e) => dispatch({ type: FormReducerAction.Update, field: FormFieldId.infoDescription, payload: e.target.value })}
                        onBlur={() => dispatch({ type: FormReducerAction.Blur, field: FormFieldId.infoDescription })}
                        error={infoDescription.error}
                    />
                    <SizedBox height={8} />
                    <TextInput
                        label={t('address line 1')}
                        placeholder=""
                        value={infoAddress1.value}
                        onChange={(e) => dispatch({ type: FormReducerAction.Update, field: FormFieldId.infoAddress1, payload: e.target.value })}
                        onBlur={() => dispatch({ type: FormReducerAction.Blur, field: FormFieldId.infoAddress1 })}
                        error={infoAddress1.error}
                    />
                    <SizedBox height={8} />
                    <TextInput
                        label={t('address line 2')}
                        placeholder=""
                        value={infoAddress2.value}
                        onChange={(e) => dispatch({ type: FormReducerAction.Update, field: FormFieldId.infoAddress2, payload: e.target.value })}
                        onBlur={() => dispatch({ type: FormReducerAction.Blur, field: FormFieldId.infoAddress2 })}
                        error={infoAddress2.error}
                    />
                    <SizedBox height={8} />
                    <TextInput
                        label={t('contact email')}
                        placeholder={t('contact email placeholder')}
                        value={infoEmail.value}
                        onChange={(e) => dispatch({ type: FormReducerAction.Update, field: FormFieldId.infoEmail, payload: e.target.value })}
                        onBlur={() => dispatch({ type: FormReducerAction.Blur, field: FormFieldId.infoEmail })}
                        error={infoEmail.error}
                    />
                    <SizedBox height={8} />
                    <TextInput
                        label={t('phone number')}
                        placeholder={t('phone number placeholder')}
                        value={infoPhoneNumber.value}
                        onChange={(e) => dispatch({ type: FormReducerAction.Update, field: FormFieldId.infoPhoneNumber, payload: e.target.value })}
                        onBlur={() => dispatch({ type: FormReducerAction.Blur, field: FormFieldId.infoPhoneNumber })}
                        error={infoPhoneNumber.error}
                    />
                </div>
                <div className={styles.padder} />
                <div className={styles.col}>
                    <AppInfoPreview stage={3} />
                </div>
            </div>
        </StepContainer>
    );
};

import { Title } from '@mantine/core';
import { ReactNode } from 'react';
import styles from './StepContainer.module.css';

interface IStepContainer {
    children: ReactNode;
    title: string;
}

export const StepContainer = ({ children, title }: IStepContainer) => {
    return (
        <div className={styles.container}>
            <Title order={4}>{title}</Title>
            {children}
        </div>
    );
};

import { Text, TextInput, Title } from '@mantine/core';
import { useContext } from 'react';
import FormContext from '../../../contexts/FormContext';
import { FormFieldId, FormReducerAction } from '../../../contexts/FormReducer';
import TranslationContext from '../../../contexts/TranslationContext';
import { AppInfoPreview } from '../../Previews/AppInfoPreview/AppInfoPreview';
import { SizedBox } from '../../SizedBox/SizedBox';
import { StepContainer } from '../../StepContainer/StepContainer';

export const AppInfo = () => {
    const { t } = useContext(TranslationContext);
    const { state: { companyName, companyDomain }, dispatch } = useContext(FormContext);

    return (
        <StepContainer title={t("app name question")}>
            <Text align='center'>{t("app name description")}</Text>
            <SizedBox height={16} />
            <TextInput
                placeholder={t("My Company Designer")}
                value={companyName.value}
                onChange={(e) => dispatch({ type: FormReducerAction.Update, field: FormFieldId.companyName, payload: e.target.value })}
                onBlur={() => dispatch({ type: FormReducerAction.Blur, field: FormFieldId.companyName })}
                error={companyName.error}
            />
            <SizedBox height={24} />
            <Title order={4}>{t('app domain question')}</Title>
            <SizedBox height={8} />
            <Text align='center'>{t('app domain details')}</Text>
            <SizedBox height={16} />
            <TextInput
                placeholder="designer.mycompany.com"
                value={companyDomain.value}
                onChange={(e) => dispatch({ type: FormReducerAction.Update, field: FormFieldId.companyDomain, payload: e.target.value })}
                onBlur={() => dispatch({ type: FormReducerAction.Blur, field: FormFieldId.companyDomain })}
                error={companyDomain.error}
            />
            <AppInfoPreview stage={0} />
        </StepContainer>
    );
};

import en from "../intl/en.json";
import fr from "../intl/fr.json";

import React, { useCallback, useContext } from 'react';
import WixContext from "./WixContext";

interface ITranslationContext {
    t: (key: string) => string;
}

const defaultTranslationContextValues: ITranslationContext = {
    t: (key) => key,
};

export const TranslationContext = React.createContext(defaultTranslationContextValues);

export const TranslationContextProvider = ({ children }: { children: React.ReactNode }) => {
    const { language, loaded } = useContext(WixContext);

    const t = useCallback((key: string) => {
        key = key.toLocaleLowerCase();
        if (language === 'fr') {
            return ((fr as any)[key] ?? (en as any)[key]) ?? key;
        }
        return (en as any)[key] ?? key;
    }, [language]);

    if (loaded === null) {
        return <div />;
    }

    if (loaded === false) {
        <div>{t('invalid data error')}</div>
    }

    return (
        <TranslationContext.Provider value={{
            t,
        }}>
            {children}
        </TranslationContext.Provider>
    );
}

export default TranslationContext;

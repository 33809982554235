import React, { useContext, useReducer } from 'react';
import { formReducer, FormReducerAction, IFormReducerState, initialFormReducerState, IReducerAction } from './FormReducer';
import WixContext from './WixContext';

interface IFormContext {
    state: IFormReducerState;

    dispatch: (action: IReducerAction<FormReducerAction>) => void;
    onFinish: () => Promise<void>;
}

const defaultFormContextValues: IFormContext = {
    state: initialFormReducerState(),

    dispatch: () => { },
    onFinish: async () => { },
};

export const FormContext = React.createContext(defaultFormContextValues);

export const FormContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [state, dispatch] = useReducer(formReducer, initialFormReducerState());
    const { data } = useContext(WixContext);

    const onFinish = async () => {
        const formData = new FormData();
        formData.append("appName", state.companyName.value);
        formData.append("appDomain", state.companyDomain.value);
        formData.append("primaryColor", state.primaryColor.value);
        formData.append("secondaryColor", state.secondaryColor.value);
        formData.append("companyName", state.infoName.value);
        formData.append("companyDescription", state.infoDescription.value);
        formData.append("companyAddress1", state.infoAddress1.value);
        formData.append("companyAddress2", state.infoAddress2.value);
        formData.append("companyEmail", state.infoEmail.value);
        formData.append("companyPhone", state.infoPhoneNumber.value);
        formData.append("languages", JSON.stringify(state.languages.value));
        formData.append("noReplyEmail", state.noReplyEmail.value);
        formData.append("technicalName", state.technicalName.value);
        formData.append("technicalEmail", state.technicalEmail.value);
        formData.append("modules", JSON.stringify(data));
        if (state.logoImage.value.blob) formData.append("icon", state.logoImage.value.blob, "logo.png");
        if (state.favIcon.value.blob) formData.append("favicon", state.favIcon.value.blob, "favicon.png");

        if (process.env.REACT_APP_DEMO === "true") {
            console.log("Form submitted in demo mode", formData);
        } else {
            const res = await fetch("https://www.spacedesigner3d.com/landing/submit", {
                method: 'POST',
                body: formData,
            });
            if (res.status !== 200) {
                console.error(res);
                throw new Error(`${res.status} - error submitting form`);
            }
        }
    }

    return (
        <FormContext.Provider value={{
            state,

            dispatch,
            onFinish,
        }}>
            {children}
        </FormContext.Provider>
    );
}

export default FormContext;

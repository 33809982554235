import { Button } from '@mantine/core';
import { useContext } from 'react';
import FormContext from '../../contexts/FormContext';
import { IFormReducerState } from '../../contexts/FormReducer';
import TranslationContext from '../../contexts/TranslationContext';
import { SizedBox } from '../SizedBox/SizedBox';
import styles from './StepButtons.module.css';

interface IStepButtons {
    currentStep: number;
    prevStep: () => void;
    nextStep: () => void;
}

const canContinue = (currentStep: number, state: IFormReducerState) => {
    switch (currentStep) {
        case 0:
            return state.companyName.invalid || state.companyDomain.invalid;
        case 1:
            return state.primaryColor.invalid || state.secondaryColor.invalid;
        case 2:
            return state.favIcon.invalid || state.logoImage.invalid;
        case 3:
            return state.languages.invalid;
        case 4:
            return state.noReplyEmail.invalid;
        case 5:
            return state.infoName.invalid || state.infoDescription.invalid || state.infoAddress1.invalid || state.infoAddress2.invalid || state.infoPhoneNumber.invalid;
        case 6:
            return state.technicalName.invalid || state.technicalEmail.invalid;
        default:
            return false;
    }
}

export const StepButtons = ({ currentStep, prevStep, nextStep }: IStepButtons) => {
    const { t } = useContext(TranslationContext);
    const { state } = useContext(FormContext);

    return (
        <div className={styles.container}>
            {currentStep > 0 && currentStep < 7 &&
                <Button variant='outline' onClick={prevStep}>{t('back')}</Button>
            }
            {currentStep > 0 && currentStep < 7 &&
                <SizedBox width={16} />
            }
            {currentStep < 7 &&
                <Button onClick={nextStep} disabled={canContinue(currentStep, state)}>{t('next')}</Button>
            }
        </div>
    );
};

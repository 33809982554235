import { Center, Stepper } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useContext, useEffect, useState } from 'react';
import { SizedBox } from "../components/SizedBox/SizedBox";
import { StepButtons } from "../components/StepButtons/StepButtons";
import { AppInfo } from "../components/Steps/AppInfo/AppInfo";
import { BrandColors } from "../components/Steps/BrandColors/BrandColors";
import { CompanyIcons } from "../components/Steps/CompanyIcons/CompanyIcons";
import { CompanyInfo } from "../components/Steps/CompanyInfo/CompanyInfo";
import { Languages } from "../components/Steps/Languages/Languages";
import { NoReply } from "../components/Steps/NoReply/NoReply";
import { TechnicalContact } from "../components/Steps/TechnicalContact/TechnicalContact";
import FormContext from "../contexts/FormContext";
import TranslationContext from "../contexts/TranslationContext";
import styles from "./App.module.css";

export const App = () => {
    const { t } = useContext(TranslationContext);
    const { onFinish } = useContext(FormContext);
    const [error, setError] = useState<string | null>(null);
    const isXS = useMediaQuery('(max-width: 576px)');

    const [currentStep, setCurrentStep] = useState(0);
    const nextStep = () => setCurrentStep((current) => (current < 7 ? current + 1 : current));
    const prevStep = () => setCurrentStep((current) => (current > 0 ? current - 1 : current));

    useEffect(() => {  // Form finished
        let mounted = true;
        if (currentStep >= 7) {
            onFinish().catch((err) => {
                console.error(err);
                if (!mounted) return;
                setError(t('error please retry'));
                setTimeout(() => {
                    if (!mounted) return;
                    setError(null);
                    setCurrentStep(6);
                }, 5000);
            });
        }

        return () => { mounted = false; };
    }, [currentStep, t])

    return (
        <div className={styles.container}>
            <Stepper
                breakpoint="xs"
                color="dark"
                size="xs"
                active={currentStep}
                onStepClick={setCurrentStep}
            >
                <Stepper.Step allowStepSelect={currentStep < 7} label={isXS && t("application information")}>
                    <AppInfo />
                </Stepper.Step>
                <Stepper.Step allowStepSelect={currentStep < 7} label={isXS && t("brand colors")}>
                    <CompanyIcons />
                </Stepper.Step>
                <Stepper.Step allowStepSelect={currentStep < 7} label={isXS && t("company information")}>
                    <BrandColors />
                </Stepper.Step>
                <Stepper.Step allowStepSelect={currentStep < 7} label={isXS && t("languages")}>
                    <Languages />
                </Stepper.Step>
                <Stepper.Step allowStepSelect={currentStep < 7} label={isXS && t("additional information")}>
                    <NoReply />
                </Stepper.Step>
                <Stepper.Step allowStepSelect={currentStep < 7} label={isXS && t("company information")}>
                    <CompanyInfo />
                </Stepper.Step>
                <Stepper.Step allowStepSelect={currentStep < 7} label={isXS && t("technical contact")}>
                    <TechnicalContact />
                </Stepper.Step>
                <Stepper.Completed>
                    <Center>
                        {error ? error : t('thank you')}
                    </Center>
                </Stepper.Completed>
            </Stepper>
            <SizedBox height={16} />
            <StepButtons
                currentStep={currentStep}
                prevStep={prevStep}
                nextStep={nextStep}
            />
        </div>
    );
}

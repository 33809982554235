import { ColorInput, Text } from '@mantine/core';
import { useContext } from 'react';
import FormContext from '../../../contexts/FormContext';
import { FormFieldId, FormReducerAction } from '../../../contexts/FormReducer';
import TranslationContext from '../../../contexts/TranslationContext';
import { AppInfoPreview } from '../../Previews/AppInfoPreview/AppInfoPreview';
import { SizedBox } from '../../SizedBox/SizedBox';
import { StepContainer } from '../../StepContainer/StepContainer';

export const BrandColors = () => {
    const { t } = useContext(TranslationContext);
    const { state: { primaryColor, secondaryColor }, dispatch } = useContext(FormContext);
    return (
        <StepContainer title={t('primary color question')}>
            <Text align='center'><b>{t('primary color details bold')}</b> {t('primary color details normal')}</Text>
            <SizedBox height={8} />
            <ColorInput
                placeholder='#C5D899'
                value={primaryColor.value}
                onChange={(color) => dispatch({ type: FormReducerAction.Update, field: FormFieldId.primaryColor, payload: color })}
                onBlur={() => dispatch({ type: FormReducerAction.Blur, field: FormFieldId.primaryColor })}
                error={primaryColor.error}
            />
            <SizedBox height={16} />
            <Text align='center'><b>{t('secondary color details bold')}</b> {t('secondary color details normal')}</Text>
            <SizedBox height={8} />
            <ColorInput
                placeholder="#E5A899"
                value={secondaryColor.value}
                onChange={(color) => dispatch({ type: FormReducerAction.Update, field: FormFieldId.secondaryColor, payload: color })}
                onBlur={() => dispatch({ type: FormReducerAction.Blur, field: FormFieldId.secondaryColor })}
                error={secondaryColor.error}
            />
            <AppInfoPreview stage={2} />
        </StepContainer>
    );
};

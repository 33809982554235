import React, { useEffect, useState } from 'react';

interface IWixContext {
    loaded: boolean | null;
    data: string[];
    language: string;
}

const defaultWixContextValues: IWixContext = {
    loaded: null,
    data: [],
    language: "en",
};

export const WixContext = React.createContext(defaultWixContextValues);

export const WixContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [loaded, setLoaded] = useState<boolean | null>(null);
    const [data, setData] = useState<string[]>([]);
    const [language, setLanguage] = useState<string>("");

    useEffect(() => {
        if (process.env.REACT_APP_DEMO === "true") {
            const exampleMessageData = {
                type: "ping",
                itemString: "[\"Section & Elevation Views\",\"Backgrounds\",\"Outdoor Design Tools\",\"Pipe Design Tool\",\"Shape Tool\",\"API\",\"Roof Designer\",\"Safety Distance Tools\",\"Room Templates\"]",
                language: "en",
            };
            window.postMessage(exampleMessageData, "*");
        }
    }, []);

    useEffect(() => {
        let interval = setInterval(() => {
            if ((window as any).wixLang) {
                setLoaded(true);
                setData((window as any).wixData);
                setLanguage((window as any).wixLang);
                if ((window as any).wixData === false) {
                    setLoaded(false);
                }
                clearInterval(interval);
            }
        }, 50);

        return () => { clearInterval(interval); }
    }, []);

    return (
        <WixContext.Provider value={{
            loaded,
            data,
            language,
        }}>
            {children}
        </WixContext.Provider>
    );
}

export default WixContext;

import { Text, TextInput } from '@mantine/core';
import { useContext } from 'react';
import FormContext from '../../../contexts/FormContext';
import { FormFieldId, FormReducerAction } from '../../../contexts/FormReducer';
import TranslationContext from '../../../contexts/TranslationContext';
import { SizedBox } from '../../SizedBox/SizedBox';
import { StepContainer } from '../../StepContainer/StepContainer';

export const NoReply = () => {
    const { t } = useContext(TranslationContext);
    const { state: { noReplyEmail }, dispatch } = useContext(FormContext);

    return (
        <StepContainer title={t('no reply title')}>
            <Text>{t('no reply details')}</Text>
            <SizedBox height={16} />
            <TextInput
                placeholder="noreply@yourcompany.com"
                value={noReplyEmail.value}
                onChange={(e) => dispatch({ type: FormReducerAction.Update, field: FormFieldId.noReplyEmail, payload: e.target.value })}
                onBlur={() => dispatch({ type: FormReducerAction.Blur, field: FormFieldId.noReplyEmail })}
                error={noReplyEmail.error}
            />
        </StepContainer>
    );
};

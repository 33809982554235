import { Button, Group, Text, Title } from '@mantine/core';
import { Dropzone, FileWithPath, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { IconPhoto } from '@tabler/icons';
import "cropperjs/dist/cropper.css";
import { useContext, useEffect, useRef, useState } from 'react';
import { Cropper, ReactCropperElement } from 'react-cropper';
import FormContext from '../../../contexts/FormContext';
import { FormFieldId, FormReducerAction } from '../../../contexts/FormReducer';
import TranslationContext from '../../../contexts/TranslationContext';
import { AppInfoPreview } from '../../Previews/AppInfoPreview/AppInfoPreview';
import { SizedBox } from '../../SizedBox/SizedBox';
import { StepContainer } from '../../StepContainer/StepContainer';
import styles from './CompanyIcons.module.css';

interface IUploadedFile {
    file: FileWithPath;
    url: string;
}

export const CompanyIcons = () => {
    const { t } = useContext(TranslationContext);
    const { state: { logoImage, favIcon }, dispatch } = useContext(FormContext);
    const cropper = useRef<ReactCropperElement>(null);
    const [uploadedFile, setUploadedFile] = useState<IUploadedFile | null>(null);
    const [uploadedFieldId, setUploadedFieldId] = useState<FormFieldId>(FormFieldId.logoImage);

    const handleFileDrop = (files: FileWithPath[], fieldId: FormFieldId) => {
        if (files.length < 1) return;
        const file = files[0];
        const url = URL.createObjectURL(file);
        setUploadedFieldId(fieldId);
        setUploadedFile({ file, url });
    }

    useEffect(() => {
        if (!uploadedFile || !uploadedFile.url || !uploadedFile.file) return;
        // openConfirmModal({
        //     title: 'crop title',
        //     children: (
        //         <Cropper
        //             ref={cropper}
        //             src={uploadedFile?.url}
        //             style={{ height: '100%', width: '100%', maxHeight: '80%' }}
        //             aspectRatio={uploadedFieldId === FormFieldId.logoImage ? 444 / 84 : 1}
        //             guides={false}
        //             crop={() => { }}
        //             allowTransparency={true}
        //         />
        //     ),
        //     labels: { confirm: 'Confirm', cancel: 'Cancel' },
        //     onCancel: () => {
        //         setUploadedFile((uploadedFile) => {
        //             if (uploadedFile?.url) {
        //                 URL.revokeObjectURL(uploadedFile.url);
        //             }
        //             return null;
        //         });
        //     },
        //     onConfirm: () => {
        //         const canvas = cropper.current?.cropper?.getCroppedCanvas();
        //         const dataUrl = canvas?.toDataURL("image/png");
        //         canvas?.toBlob((blob) => {
        //             dispatch({
        //                 type: FormReducerAction.Update,
        //                 field: uploadedFieldId,
        //                 payload: { blob, dataUrl },
        //             });
        //             dispatch({
        //                 type: FormReducerAction.Blur,
        //                 field: uploadedFieldId,
        //             });
        //         });
        //         setUploadedFile((uploadedFile) => {
        //             if (uploadedFile?.url) {
        //                 URL.revokeObjectURL(uploadedFile.url);
        //             }
        //             return null;
        //         });
        //     },
        // });
    }, [uploadedFile, dispatch, uploadedFieldId]);

    return (
        <StepContainer title={t('company logo and favicon question')}>
            {uploadedFile !== null &&
                <div className={styles.cropModalBg}>
                    <div className={styles.cropContainer}>
                        <SizedBox height={12} />
                        <Title order={4}>{t('crop title')}</Title>
                        <SizedBox height={16} />
                        <Cropper
                            ref={cropper}
                            src={uploadedFile?.url}
                            style={{ height: '400px', width: '400px', maxHeight: '80%', maxWidth: '90%' }}
                            aspectRatio={uploadedFieldId === FormFieldId.logoImage ? 444 / 84 : 1}
                            guides={false}
                            crop={() => { }}
                            allowTransparency={true}
                        />
                        <SizedBox height={16} />
                        <div className={styles.buttonContainer}>
                            <Button variant='outline' onClick={() => {
                                setUploadedFile((uploadedFile) => {
                                    if (uploadedFile?.url) {
                                        URL.revokeObjectURL(uploadedFile.url);
                                    }
                                    return null;
                                });
                            }}>
                                {t('back')}
                            </Button>
                            <SizedBox width={16} />
                            <Button onClick={() => {
                                const canvas = cropper.current?.cropper?.getCroppedCanvas();
                                const dataUrl = canvas?.toDataURL("image/png");
                                canvas?.toBlob((blob) => {
                                    dispatch({
                                        type: FormReducerAction.Update,
                                        field: uploadedFieldId,
                                        payload: { blob, dataUrl },
                                    });
                                    dispatch({
                                        type: FormReducerAction.Blur,
                                        field: uploadedFieldId,
                                    });
                                });
                                setUploadedFile((uploadedFile) => {
                                    if (uploadedFile?.url) {
                                        URL.revokeObjectURL(uploadedFile.url);
                                    }
                                    return null;
                                });
                            }}>
                                {t('next')}
                            </Button>
                        </div>
                        <SizedBox height={16} />
                    </div>
                </div>
            }
            <Text>{t('company logo description')}</Text>
            <SizedBox height={8} />
            <Text>{t('company logo')}</Text>
            <SizedBox height={8} />
            <Dropzone
                accept={IMAGE_MIME_TYPE}
                onDrop={(files) => handleFileDrop(files, FormFieldId.logoImage)}
                onReject={() => { }}
                maxSize={3 * 1024 ** 2}
                style={{ maxWidth: '100%' }}
            >
                <Group position="center" spacing="xl" style={{ width: 480, minHeight: 100, maxWidth: '98%', maxHeight: '100%', pointerEvents: 'none', flexDirection: 'column', alignItems: 'center' }}>
                    {logoImage?.value?.dataUrl &&
                        <img alt="" src={logoImage.value.dataUrl} style={{ width: 444, height: 88, maxWidth: '98%', maxHeight: '100%' }} />
                    }

                    {!(logoImage?.value?.dataUrl) &&
                        <>
                            <IconPhoto size={50} stroke={1.5} />
                            <Text size={"xl"} inline>
                                {t('company logo drop line 1')}
                            </Text>
                            <Text size="sm" color="dimmed" inline mt={7}>
                                {t('company logo drop line 2')}
                            </Text>
                        </>
                    }
                </Group>
            </Dropzone>
            <SizedBox height={16} />
            <Text>{t('company favicon')}</Text>
            <SizedBox height={8} />
            <Dropzone
                accept={IMAGE_MIME_TYPE}
                onDrop={(files) => handleFileDrop(files, FormFieldId.favIcon)}
                onReject={() => { }}
                maxSize={3 * 1024 ** 2}
                style={{ maxWidth: '100%' }}
            >
                <Group position="center" spacing="xl" style={{ width: 480, minHeight: 100, maxWidth: '98%', maxHeight: '100%', pointerEvents: 'none', flexDirection: 'column', alignItems: 'center' }}>
                    {favIcon?.value?.dataUrl &&
                        <img alt="" src={favIcon.value.dataUrl} style={{ width: 88, height: 88, maxWidth: '98%', maxHeight: '100%' }} />
                    }

                    {!(favIcon?.value?.dataUrl) &&
                        <>
                            <IconPhoto size={50} stroke={1.5} />
                            <Text size="xl" inline>
                                {t("company favicon drop line 1")}
                            </Text>
                            <Text size="sm" color="dimmed" inline mt={7}>
                                {t("company favicon drop line 2")}
                            </Text>
                        </>
                    }
                </Group>
            </Dropzone>
            <AppInfoPreview stage={1} />
        </StepContainer>
    );
};

export const validAddress = (url: string) => {
    if (url.includes(' ')) return false;
    const domainParts = url.split('.');
    if (domainParts.length < 2 || domainParts.includes('')) return false;
    return true;
}

export const validColor = (color: string) => {
    return /^#[0-9A-F]{6}$/i.test(color);
}

const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const validEmail = (email: string) => {
    return emailRegEx.test(email);
}

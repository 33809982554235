import { Text, TextInput } from '@mantine/core';
import { useContext } from 'react';
import FormContext from '../../../contexts/FormContext';
import { FormFieldId, FormReducerAction } from '../../../contexts/FormReducer';
import TranslationContext from '../../../contexts/TranslationContext';
import { SizedBox } from '../../SizedBox/SizedBox';
import { StepContainer } from '../../StepContainer/StepContainer';

export const TechnicalContact = () => {
    const { t } = useContext(TranslationContext);
    const { state: { technicalName, technicalEmail }, dispatch } = useContext(FormContext);

    return (
        <StepContainer title={t('technical contact title')}>
            <Text>{t('technical contact description')}</Text>
            <SizedBox height={16} />
            <TextInput
                placeholder={t('technical contact name placeholder')}
                value={technicalName.value}
                onChange={(e) => dispatch({ type: FormReducerAction.Update, field: FormFieldId.technicalName, payload: e.target.value })}
                onBlur={() => dispatch({ type: FormReducerAction.Blur, field: FormFieldId.technicalName })}
                error={technicalName.error}
            />
            <SizedBox height={8} />
            <TextInput
                placeholder={t('technical contact email placeholder')}
                value={technicalEmail.value}
                onChange={(e) => dispatch({ type: FormReducerAction.Update, field: FormFieldId.technicalEmail, payload: e.target.value })}
                onBlur={() => dispatch({ type: FormReducerAction.Blur, field: FormFieldId.technicalEmail })}
                error={technicalEmail.error}
            />
        </StepContainer>
    );
};
